import { CaseStudy, CaseStudyTemplate, Company } from "../CaseStudy";
import technologies from "../technologies";
import equitrackThumbnail from "../../../../assets/img/caseStudies/EQV1ALT2.webp";
import equitrackLogo from "../../../../assets/img/clients/equ-logoAlt.webp";

export default function EquitrackV1() {
    const caseStudy = new CaseStudyTemplate(
        "Equi-Track V1",
        (
            <div>
                <p>
                    A comprehensive online equipment and asset management tool for tracking, inspecting, servicing and invoicing the movement of assets.  Equi-track was designed and built initially in 2010 and has evolved to keep up with current technologies and industry requirements. Key features include:
                    <ul style={{ textAlign: "left" }}>
                        <li>An Asset Register with photos and documentation.</li>
                        <li>Tracking the location and hiring out of assets.</li>
                        <li>Management of asset condition, including inspection and service requirements.</li>
                        <li>Interactive dashboards and reports for <a href="https://www.linkedin.com/feed/update/urn:li:activity:6967506886464057345">utilisation statistics</a> and invoicing.</li>
                    </ul>
                </p>
                <p>
                    Since its conception in 2010, DEV4 have delivered many customised versions of Equi-track V1 to customer specifications, including a version for <a href="https://www.muehlhan.com/">Muehlhan Europe</a>  which has additional features such the choice of different languages and advanced utilisation reporting.
                </p>
                <p>
                    Established in 2010 | Continued user base: UK, Germany, France, Netherlands, Greece |
                </p>
            </div>
        ),
        // Project Showcase Thumbnail
       
        equitrackThumbnail,
        new Company(
            "Equitrack",
            // Company Logo
            equitrackLogo,
            {
                width: "22%",
            }
        ),
        "https://equitrack.co.uk/",
        [
            technologies.aspNET,
            technologies.telerik,
            technologies.azure,
            technologies.sqlServer
        ]
    )

    return (
        <CaseStudy caseStudy={caseStudy} thumbSize="90%"></CaseStudy>
    )
}