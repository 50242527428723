import "./universityEngagement.css";

export default function UniversityEngagement() {
    return (
        <section data-aos="fade-up" data-aos-duration="750" className="university-engagement-section d4-container">
            <div className="university-engagement-content">
                <h1 className="display-4 text-lg-start pb-sm-2 pb-md-3">
                    University and graduate engagement 
                </h1>
                <p className="fs-lg text-lg-start mx-auto mx-lg-0">
                    Our program of working with university students and graduates within our team has ensured that our system designs and technologies are always ahead of the game.
                </p>

                <p className="fs-lg text-lg-start mb-0">
                    Our paid placements and on-the-job training of students over the last 12 years has proved to be beneficial to both the development of the next generation of software developers and the injection of enthusiasm and valuable skills into our team. 
                </p>
                
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className="university-engagement-thumbnail"></div>
        </section>
    )
}