// Libraries
import { useEffect } from "react";
import GoogleAnalytics from "react-ga4";

// Components.
import Hero from "../components/pages/home/Hero/Hero";
import Services from "../components/pages/home/Services/Services";
import Clients from "../components/pages/home/clients/Clients";
import About from "../components/pages/home/about/About";
import Tools from "../components/pages/home/tools/Tools";
import Contact from "../components/pages/home/contact/Contact";
import UniversityEngagement from "../components/pages/home/universityEngagement/UniversityEngagement";
import Testimonials from "../components/pages/home/testimonials/Testimonials";

export default function Home() {
    useEffect(() => {
        GoogleAnalytics.send({ hitType: "pageview", page: window.location.pathname, title: "Home" });
        
        // Needs fixed.. on mounted if a hash link is present it should scroll.
        // const hashLink = window.location.hash;
        // if (hashLink) {
        //   // Remove the leading '#' from the hash link to get the element id
        //   const elementId = hashLink.substring(1);
        //   // Find the target element by its id
        //   const targetElement = document.getElementById(elementId);
        //   // Scroll to the target element if it exists
        //   if (targetElement) {
        //     targetElement.scrollIntoView({ behavior: 'smooth' });
        //   }
        // }
    }, []);

    return (
        <main>
            <Hero />
            <Services />
            <Clients />
            <About />
            <UniversityEngagement />
            <Tools />
            <Testimonials />
            <Contact />
        </main>
    )
}