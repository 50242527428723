/*
 * Tools section.
 * This section displays all the technology DEV4 online uses.
 */

import "./tools.css";
import Tool from "./Tool";
import PrimaryAnchorBtn from "../../../common/Anchors/PrimaryAnchorBtn";

/*
 * You can use a tool called Trimmy, which can be found at https://trimmy.io/, 
 * to remove the white border or padding on the logos of the images.
 * It's recommended that images have the same or similar aspect ratios 
 * to ensure consistency and avoid any distortion or stretching. 
 * Therefore, when importing images, 
 * keep in mind the importance of maintaining consistent aspect ratios.
 */

import azureLogo from "../../../../assets/img/tools/AzureLogo.webp";
import aspNetLogo from "../../../../assets/img/tools/MicrosoftLogo.webp";
import telerikLogo from "../../../../assets/img/tools/TelerikLogo.webp";
import sqlServerLogo from "../../../../assets/img/tools/SQLServerLogo.webp";
import vueLogo from "../../../../assets/img/tools/VueLogo.webp";
import reactLogo from "../../../../assets/img/tools/ReactLogo.webp";
import blazorLogo from "../../../../assets/img/tools/BlazorLogo.webp";
import netCoreLogo from "../../../../assets/img/tools/NETCore.webp";
import xamarinLogo from "../../../../assets/img/tools/XamarinLogo.webp";

export default function Tools() {
    return (
        <section data-aos="fade-up" className="tools-section">
            <div className="tools-card d4-container">
                <div className="tools-container">
                    {/*List of tools.*/}
                    <Tool link="https://azure.microsoft.com/en-gb/" name="Microsoft Azure" logo={azureLogo} />
                    <Tool link="https://dotnet.microsoft.com/en-us/apps/aspnet" name="Microsoft ASP.NET" logo={aspNetLogo} />
                    <Tool link="https://www.telerik.com/" name="Telerik" logo={telerikLogo} />
                    <Tool link="https://www.microsoft.com/en-GB/sql-server/" name="Microsoft SQL Server" logo={sqlServerLogo} />
                    <Tool link="https://dotnet.microsoft.com/en-us/apps/aspnet" name="ASP.NET" logo={netCoreLogo} bgStyle={{
                        backgroundSize: "25%"
                    }} />
                    <Tool link="https://vuejs.org/" name="Vue.js" logo={vueLogo} lowPriority={true} />
                    <Tool link="https://react.dev/" name="React" logo={reactLogo} />
                    <Tool link="https://dotnet.microsoft.com/en-us/apps/aspnet/web-apps/blazor" name="Blazor" logo={blazorLogo} />
                    <Tool link="https://dotnet.microsoft.com/en-us/apps/xamarin" name="Xamarin" logo={xamarinLogo} />
                </div>

                <div className="tools-section-descriptor">
                    <h1 className="h1 mb-4">
                        {/*Section Title*/}
                        The tools we work with
                    </h1>
                    <p className="pb-3 mb-3 mb-lg-4">
                        {/*Section Description*/}
                        At DEV4 Online, we work with a robust and reliable tech stack that includes a wide range of tools and technologies. 

                        We use robust and reliable technologies, including Microsoft Azure, Blazor, Telerik, Vue.js, React, and SQL Server, tailored to each project to deliver high-performing solutions that exceed client expectations.
                    </p>
                    <PrimaryAnchorBtn title="Find out more" path="/#contact" rounded="true" theme="secondary" />
                </div>
            </div>
        </section>
    )
}