import "./carousel.css"
import CarouselSlide from "./CarouselSlide";

/**
 * Code adapted from https://www.youtube.com/watch?v=nAjR0Oj0J8E.
 * Reusable infinite seamless scrolling carousel component.
 * */
export default function InfiniteCarousel({ speed, children }) {
    return (
        <div className="carousel" data-speed={speed}>
            <CarouselSlide speed={speed}>
                { children }
            </CarouselSlide>
            { /* Once first carousel-slide is finished append second slide after so it is seamless. */ }
            <CarouselSlide speed={speed}>
                {children}
            </CarouselSlide>
        </div>
    );
}
