import "./services.css";
import Service from "./Service";
import Section from "../../../common/Section/Section";

// Icons for each service
import responsiveWebAppsIcon from "../../../../assets/img/icons/services/RWA.svg";
import databaseDevIcon from "../../../../assets/img/icons/services/DD.svg";
import microsoftOfficeAppsIcon from "../../../../assets/img/icons/services/MOA.svg";
import cloudAppsIcon from "../../../../assets/img/icons/services/CA.svg";
import technicalDirectionIcon from "../../../../assets/img/icons/services/TD.svg";
import projectMgmtIcon from "../../../../assets/img/icons/services/PM.svg";

// Thumbnail photos for each service.
import golfingImage from "../../../../assets/img/other/golfing.jpg";
import tailoredSuiteImage from "../../../../assets/img/other/tailoredSuite.webp";
import helicopterImage from "../../../../assets/img/other/helicopter.webp";
import headsetVRImage from "../../../../assets/img/other/CreativeVRHeadset.webp";
import publicSpeakerImage from "../../../../assets/img/other/PublicSpeaker.webp";
import teamworkingImage from "../../../../assets/img/other/Teamworking.webp";

/**
 * List of service objects that will be rendered on to the document.
 * */
const services = [
    {
        icon: responsiveWebAppsIcon,
        title: "Deliver your services with software",
        subtitle: "Buy more time",
        description: "Working with you to design a system that can deliver your services 24/7 with reduced management from yourself.",
        hoverImage: golfingImage
    },
    {
        icon: databaseDevIcon,
        title: "Create a competitive edge",
        subtitle: "Enhance your image",
        description: "Designing a razor sharp, tailored software system to deliver your services will give you a strong competitive advantage.",
        hoverImage: tailoredSuiteImage
    },
    {
        icon: microsoftOfficeAppsIcon,
        title: "Increase your profits",
        subtitle: "Reduce costs",
        description: "Customising software specifically to your processing and management requirements, can significantly reduce your overheads.",
        hoverImage: helicopterImage
    },
    {
        icon: cloudAppsIcon,
        title: "Turn your creative ideas into a reality",
        subtitle: "Realise your vision",
        description: "Our experience in design and delivery makes it surprisingly fast for us to turn your ideas into a working system.",
        hoverImage: headsetVRImage
    },
    {
        icon: technicalDirectionIcon,
        title: "Improve customer retention",
        subtitle: "Increase popularity",
        description: "Designing the correct online interface with your clients will keep them happy and reliant on your services for longer.",
        hoverImage: publicSpeakerImage
    },
    {
        icon: projectMgmtIcon,
        title: "Eradicate frustration",
        subtitle: "Create a happier work force",
        description: "Identify and remove inconsistencies in your business by creating organised, uniform management of information with employees and clients.",
        hoverImage: teamworkingImage
    }
]

export default function Services() {
    return (
        /*

        */
        <Section title="What we can do with you" subtitle="We don’t just deliver software on time and within budget. We will work with you to ensure your systems align to all aspects of your business." scrollID="services">
            <div className="services-container limit-width">
                {
                    // Bind service to a Service component.
                    services.map(service => (
                        <Service icon={service.icon} title={service.title} subtitle={service.subtitle} description={service.description} hoverImage={service.hoverImage} key={service.title} />
                    ))
                }
            </div>
        </Section>
    )
}