import { Route, Switch } from "react-router-dom";

// Import pages (JSX components) below.
import Home from "./pages/Home";
import CaseStudies from "./pages/CaseStudies";
import NotFound from "./pages/errors/404";

/**
 * @description Maps pages to their matching route/path.  
 * */
export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>

            <Route exact path="/CaseStudies">
                <CaseStudies />
            </Route>

            { /* Include more routes below. */}

            { /* Error 404 */}
            <Route path="" component={NotFound} />
        </Switch>    
    )
}