/*
 * The section that displays client / associates of DEV4 online.
*/

import "./clients.css";
/* Component representing a client of Dev4 online not a software client. */
import Client from "./Client";
/* Other components. */
import Section from "../../../common/Section/Section";
import InfiniteCarousel from "../../../common/InfiniteCarousel/InfiniteCarousel";

/* Client Logos */
import namakaLogo from "../../../../assets/img/clients/namaka.webp";
import namakaComplianceLogo from "../../../../assets/img/clients/namaka-compliance.webp";
import clarksonsLogo from "../../../../assets/img/clients/clarksons.webp";
import temsLogo from "../../../../assets/img/clients/tems.webp";
import oceaneeringLogo from "../../../../assets/img/clients/oceaneering.webp";
import muehlhanLogo from "../../../../assets/img/clients/muehlhan.webp";
import altradLogo from "../../../../assets/img/clients/altrad.webp";
import tabLogo from "../../../../assets/img/clients/tab.webp";
import budgeFoundationLogo from "../../../../assets/img/clients/budgefoundation.webp";
import udnyCouncilLogo from "../../../../assets/img/clients/udnyComm.webp";
import risLogo from "../../../../assets/img/clients/RISLOGO_INVERT_ALT.webp";

export default function Clients() {
    return (
        <Section title="Innovating and collaborating since 2004" subtitle="Clients and partners who have become our friends over many years" scrollID="clients">
            <InfiniteCarousel speed="40s">
                {/*List of companies.*/}
                <Client logo={namakaLogo} companyName="Namaka Subsea" website="https://www.namakasubsea.com/" />
                <Client logo={namakaComplianceLogo} companyName="Namaka Compliance" website="https://www.namakacompliance.com/" />
                <Client logo={clarksonsLogo} companyName="Clarksons" website="https://www.clarksons.com/" />
                <Client logo={temsLogo} companyName="TEMS" website="https://tems-international.com/" />
                <Client logo={oceaneeringLogo} companyName="Oceaneering" website="https://www.oceaneering.com/" />
                <Client logo={muehlhanLogo} companyName="Muehlhan" website="https://www.muehlhan.com/" />
                <Client logo={altradLogo} companyName="Altrad" website="https://www.altrad.com/" />
                {/* <Client logo={tabLogo} companyName="TAB" website="https://www.thealternativeboard.co.uk/" /> */}
                <Client logo={budgeFoundationLogo} companyName="The Budge Foundation" website="http://budgefoundation.org/" />
                <Client logo={udnyCouncilLogo} companyName="Udny Community Council" website="http://udny.online/" />
                <Client logo={risLogo} companyName="RIS Inspect" website="https://riginspectionservices.com/" />
            </InfiniteCarousel>
        </Section>
    )
}

