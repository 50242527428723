import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Quote from "./Quote";
import "swiper/css"; 

export default function QuoteContentContainer({ testimonials, handleSlideChange, prevRef, nextRef }) {
    return (
        <Swiper
            modules={[Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            onSlideNextTransitionStart={() => handleSlideChange(true)}
            onSlidePrevTransitionStart={() => handleSlideChange(false)}
            /*using the refs instead of className as more reusable.*/
            navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
            }}
            pagination={{ clickable: true }}
        >
            {
                // Loop through each quote.
                testimonials.map(quote => (
                    <SwiperSlide key={quote.key}>
                        <Quote heading={quote.quoteHeading} content={quote.quoteContent} />
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}