import AboutItem from "../AboutItem";
import pizzaIcon from "../../../../../assets/img/icons/shapes/pizza.svg";
import ActionTextIconAnchor from "../../../../common/Anchors/ActionTextIconAnchor";
import DesignTogetherThumbnail from "../../../../../assets/img/other/GroupDesign.webp";

export default function DesignTogether() {
    return (
        <AboutItem
            heading="Let’s Design Your System Together"
            thumbnail={DesignTogetherThumbnail}
            floatingIcon={
                <div className="zindex-3 d-none d-lg-block position-absolute top-0 end-0 translate-middle-y w-auto pe-1 me-4">
                    <div data-aos="fade-up" data-aos-duration="950" data-aos-offset="280" className="aos-init">
                        <img src={pizzaIcon} alt="Pizza Icon" width="107" />
                    </div>
                </div>
            }
        >
            { /* About Item Content. */}
            <div>
                <p className="fs-xl pb-3 mb-1 mb-sm-2 mb-md-3 mb-lg-4" style={{ width: '50ch' }}>
                    The exciting process of turning your vision into a system involves us working with you to gain an understanding of your business and designing a system you can visualise. Our experience and understanding of the importance of communication means we get it right first time.  This ensures you get real value for your money. 
                </p>

                <br />
                <ActionTextIconAnchor title="Book a free design session" path="/#contact" />
            </div>
        </AboutItem>    
    )
}