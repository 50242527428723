import { useRef, useState, useEffect } from "react";

import "./testimonials.css";
import QuoteAuthor from "./QuoteAuthor";
import { v4 as uuidv4 } from 'uuid';
import QuoteContentContainer from "./QuoteContentContainer";
import CircleIconButton from "../../../common/Buttons/CircleIconButton";

// Testimonal Structure
class Testimonial {
    constructor(authorName, authorRole, quoteHeading, quoteContent) {
        this.authorName = authorName;
        this.authorRole = authorRole;
        this.quoteHeading = quoteHeading;
        this.quoteContent = quoteContent;
        this.key = uuidv4();
    }
} 

// List of testimonials
const testimonials = [
    new Testimonial("Jim West,  1 July 2023", "Director, Argonaut Marine Ltd", "Argonaut Marine Ltd", "I have worked with DEV4 to deliver multiple successful bespoke software solutions since 2012 and continue to recommend them for professional software design and development. Their edge is their understanding of business processes to deliver systems that fit the requirements."),
    new Testimonial("Andy Fairnie,  1 July 2023", "MD, RIS Inspection Services Ltd", "RIS Inspection Services Ltd", "DEV4 were recommended to me in 2022 and I have really enjoyed collaborating with the team through the process of designing and building our own software.  Their management of the project and fast delivery of segments of work gives me confidence in their abilities to produce the solution that will enhance my business."),
    new Testimonial("Bill Walkingshaw,  1 July 2023", "MD, TEMS International Ltd", "TEMS International Ltd", "I have worked with DEV4 since 2017 on multiple development projects and their personal investment in our business processes and the continuity of the software has been really valuable."),
    // new Testimonial("Lee Duncan,  1 July 2023", "MD, Namaka Subsea Ltd", "Namaka Subsea Ltd", "With the ongoing development of our digital services, DEV4 have worked closely with us to implement our ideas for our online presence in the market. They take the time to understand our business and feel like part of our organisation."),
    new Testimonial("Jamie Murphy,  1 July 2023", "MD, Namaka Compliance Ltd", "Namaka Compliance Ltd", "DEV4 started working with us to support and develop our, already established, bespoke software in April 2023 and we are impressed with their engagement with our team to ensure any enhancements are in-line with our business plan.")
]

export default function Testimonials() {
    // https://stackoverflow.com/a/74202954
    const [swiperKey, setSwiperKey] = useState(0);

    // Keeps track of which quote is currently displayed.
    const [quoteIndex, setQuoteIndex] = useState(0);

    // Quote to be rendered.
    const [quote, setQuote] = useState(testimonials[0]);

    // https://stackoverflow.com/a/74202954
    useEffect(() => {
        setSwiperKey(1);
    }, []);

    // Render next quote.
    const handleSlideChange = (next) => {
        const nextIndex = quoteIndex + (next ? 1 : -1);

        const currentTestimonial = testimonials[nextIndex];
        setQuote(currentTestimonial);

        setQuoteIndex(nextIndex);
    }

    // References to previous and next button.
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    return (
        <section id="testimonials">
            <div className="qoute-container">
                <div className="quote-info">
                    <i className="ai-quotes d-none d-md-block text-primary ms-n2 mb-1" style={{ fontSize: "100px" }} ></i>
                    <QuoteAuthor author={quote.authorName} role={quote.authorRole} />
                    {/* Navigation */}
                    <div className="d-flex align-items-center pb-2 pb-md-0 pt-4 mt-3">
                        <CircleIconButton icon="ai-arrow-left" ref={prevRef} />
                        <span>{quoteIndex + 1} / {testimonials.length}</span>
                        <CircleIconButton icon="ai-arrow-right" ref={nextRef} />
                    </div>
                </div>

                <QuoteContentContainer
                    testimonials={testimonials}
                    prevRef={prevRef}
                    nextRef={nextRef}
                    handleSlideChange={handleSlideChange}
                />

            </div>
        </section>
    )
}
