/**
 * Component for each tool / technology.
 * @param {String} link External link to tools documentation / website.
 * @param {String} name Name of tool.
 * @param {any} logo Imported image, preferably .webp.
 */
export default function Tool({ link, name, logo, lowPriority, bgStyle }) {
    return (
        <a href={link} data-aos="fade-up" data-aos-duration="600" target="_blank" rel="noreferrer" alt={`${name}'s webite link`} className={`tool-item ${lowPriority ? 'desktop-only' : ''}`} data-toggle="tooltip" data-placement="top" title={name} >
            <div className="logo" style={{ backgroundImage: `url('${logo}')`, ...bgStyle }}>
            </div>
        </a>
    )
}