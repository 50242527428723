import { CaseStudy, CaseStudyTemplate, Company } from "../CaseStudy";
import technologies from "../technologies";

import temsEagleThumbnail from "../../../../assets/img/caseStudies/TemsEagle.webp";
import temsLogo from "../../../../assets/img/clients/tems-cropped.webp";

export default function TemsEagle() {
    const caseStudy = new CaseStudyTemplate(
        "TEMS Eagle",
        (
            <div>
                <p>
                    DEV4 started working with TEMS International Ltd in 2017, to create a system to manage and deliver their services online, encompassing data capture and reporting in an offshore drilling environment.
                </p>
                               
                <p>
                    DEV4's Analysts worked with TEMS Engineers to design and build the initial online system to ensure fast data input, instant calculation of environmental algorithms and real-time daily reporting of centralised data. 
                </p>

                <p>
                    TEMS has become a hugely successful global operation and DEV4 continue to work with them to align their software solution with the growth of their services.
                </p>
                
                <p>
                    Intitial system build time: 8 months in 2017 | 2 subsequent major development phases | User base: Europe, Asia, the Americas and Australia |
                </p>
            </div>
        ),
        // Project Showcase Thumbnail
        temsEagleThumbnail,
        new Company(
            "TEMS",
            // Company Logo
            temsLogo
        ),
        "https://tems-international.com/tems-international-services/tems-international-eagle-reports",
        [
            technologies.aspNET,
            technologies.telerik,
            technologies.azure,
            technologies.sqlServer
        ]
    )

    return (
        <CaseStudy caseStudy={caseStudy} bgColor="rgba(85, 136, 48, 0.6)"></CaseStudy>
    )
}