import "./section.css";

/**
 * Reusable section container that contains a title and a subtitle and a slot for further elements.
 */
export default function Section({ title, subtitle, children, scrollID, className }) {
    return (
        <section className={`page-section d4-container ${className ?? ""}`} id={scrollID}>
            <div className="d-flex flex-column align-items-center" style={{ paddingBottom: "calc(var(--standard-seperation-distance) / 2)"}}>
                <h1 className="text-center">{ title }</h1>
                <p className="text-center">{ subtitle }</p>
            </div>
            { children }
        </section>    
    )
}