import { CaseStudy, CaseStudyTemplate, Company } from "../CaseStudy";
import technologies from "../technologies";

import StockTrackThumbnail from "../../../../assets/img/caseStudies/StockTrack2.webp";
import stocktrackLogo from "../../../../assets/img/clients/stocktracklogo-crop.webp";

export default function StockTrack() {
    const caseStudy = new CaseStudyTemplate(
        "Stock-Track",
        (
            <div>
                <p>
                    Stocktrack integrates with Equitrack and offers management of stock, including deliveries, pick lists, stock taking and invoicing.
                </p>

                <p>
                    Currently utilised across the globe for a multi-national maintenance company to track and invoice stock used on offshore and onshore locations.
                </p>
                
                <p>
                    Intitial system build time: 10 months | Established in 2014 | Key Clients: Altrad Group, Muehlhan Ind Services Ltd |
                </p>
            </div>
        ),
        // Project Showcase Thumbnail
        StockTrackThumbnail,
        new Company(
            "StockTrack",
            // Company Logo
            stocktrackLogo,
            {
                width: "25%",
            }
        ),
        "https://www.youtube.com/watch?v=-RSp63SUU40",
        [
            technologies.aspNET,
            technologies.telerik,
            technologies.azure,
            technologies.sqlServer
        ]
    )

    return (
        <CaseStudy caseStudy={caseStudy} thumbSize="85%" bgColor="rgba(102, 158, 203, 0.65)"></CaseStudy>
    )
}