import { CaseStudy, CaseStudyTemplate, Company } from "../CaseStudy";
import technologies from "../technologies";
import TimeherentThumbnail from "../../../../assets/img/caseStudies/Timeherent.webp";
import TimeherentLogo from "../../../../assets/img/branding/Logos/D4 Full Logo Colour.webp";

export default function Timeherent() {
    const caseStudy = new CaseStudyTemplate(
        "Timeherent",
        (
            <div>
                <p>
                    Timeherent was developed by DEV4 as in-house time management system because we found that many online timesheet systems were overly complicated to set up.  Timeherent has a simple intuitive interface so our staff can quickly record their time without impacting on their productivity.  
                </p>
                <p>
                    It is a portal for our staff to be able to view time spent, holidays and expenses as well as a management tool for viewing hours spent on client projects and analysing potential research and development budgets.
                </p>
                <p>
                    Intitial system build time: 3 months.
                </p>
            </div>
        ),
        // Project Showcase Thumbnail
        TimeherentThumbnail,
        new Company(
            "Timeherent",
            // Company Logo
            TimeherentLogo,
            {
                width: "14%",
            }
        ),
        "/#contact",
        [
            technologies.aspNET,
            technologies.telerik,
            technologies.azure,
            technologies.sqlServer
        ],
        false
    )

    return (
        <CaseStudy caseStudy={caseStudy} bgColor="rgba(245, 245, 245, 1)" thumbSize="115%"></CaseStudy>
    )
}