import { CaseStudy, CaseStudyTemplate, Company } from "../CaseStudy";
import technologies from "../technologies";

import CondiThumbnail from "../../../../assets/img/caseStudies/Condi.webp";
import CondiLogo from "../../../../assets/img/clients/CONDi_transparent.webp";

export default function Condi() {
    const caseStudy = new CaseStudyTemplate(
        "Condi",
        (
            <div>
                <p>
                    DEV4 Online Ltd worked with Muehlhan Industrial Services Ltd, UK to design and build a system for managing all aspects of surface Fabric Maintenance.  
                </p>

                <p>
                    The system was to be available online 24/7 and usable for all levels of activities, including offshore data capture, processing of work orders, management summary reporting and deeper predictive maintenance using industry norms for productivity and coating specifications.
                </p>
            </div>
        ),
        // Project Showcase Thumbnail
        CondiThumbnail,
        new Company(
            "Condi",
            // Company Logo
            CondiLogo,
            {
                width: "14.5%",
            }
        ),
        "https://youtu.be/GfmzkIRES44",
        [
            technologies.aspNET,
            technologies.telerik,
            technologies.azure,
            technologies.sqlServer
        ]
    )

    return (
        <CaseStudy caseStudy={caseStudy} bgColor="rgb(251, 190, 143)" thumbSize="90%"></CaseStudy>
    )
}