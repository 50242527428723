import AboutItem from "../AboutItem";
import PrimaryAnchorBtn from "../../../../common/Anchors/PrimaryAnchorBtn";
import redSemiCircle from "../../../../../assets/img/icons/shapes/redSemiCircle.svg";
import FutureProofItThumbnail from "../../../../../assets/img/other/Spaceman.webp";

export default function FutureProofIt() {
    return (
        <AboutItem
            heading="Future-Proof It"
            thumbnail={FutureProofItThumbnail}
            floatingIcon={
                <div className="zindex-3 d-none d-lg-block position-absolute top-0 start-50 translate-middle w-auto ms-2">
                    <div data-aos="fade-up" data-aos-duration="950" data-aos-offset="280" className="aos-init aos-animate">
                        <img src={redSemiCircle} alt="Circle Icon" width="108px" />
                    </div>
                </div>
            }
        >
            {/* 
            <div>
                <p className="fs-xl" style={{ width: "50ch" }}>
                    Find aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.
                </p>

                <ul className="list-unstyled pb-3 mb-3 mb-lg-4">
                    <li className="d-flex pt-1 mt-2 mx-2 mx-md-0"><i className="ai-check-alt text-primary fs-4 mt-n1 me-2"></i>Forecasting and recommendations</li>
                    <li className="d-flex pt-1 mt-2 mx-2 mx-md-0"><i className="ai-check-alt text-primary fs-4 mt-n1 me-2"></i>Identification of patterns, analysis</li>
                    <li className="d-flex pt-1 mt-2 mx-2 mx-md-0"><i className="ai-check-alt text-primary fs-4 mt-n1 me-2"></i>Segmentation by various methods</li>
                </ul>

                <PrimaryAnchorBtn title="Book a free consultation" theme="secondary" path="" />
            </div>
            */}
            <div>
                <p className="fs-xl pb-3 mb-1 mb-sm-2 mb-md-3 mb-lg-4" style={{ width: '50ch' }}>
                    We use the latest supported technologies and offer support packages that will suit your business plan.  Keeping up to date with the latest technologies, patches and tools will ensure your software keeps pace with your growth and the changing technical environment.
                </p>

                <PrimaryAnchorBtn title="Discuss your ideas" theme="secondary" path="/#contact" />
            </div>
   
        </AboutItem>
    )
}