import './assets/css/theme.css';
import './assets/css/custom.css';

import { BrowserRouter as Router } from "react-router-dom";
import { useEffect } from "react";

// Components
import RouterView from "./RouterView";
import Navbar from "./components/common/Navbar/Navbar";
import CookieConsent from "react-cookie-consent";
import Footer from './components/common/Footer/Footer';
import privacyPolicyPDF from './assets/documents/DEV4 Privacy Policy 18 July 2023.pdf';

// Google ReCaptcha service for forms.
import ReactRecaptchaV3 from 'react-google-recaptcha3';
import ScrollToTop from './components/common/ScrollToTop';

// Google Analytics
import GoogleAnalytics from "react-ga4";
import { getCookieConsentValue, Cookies } from "react-cookie-consent";


// Public key... not sensitive information.
const reCaptchaSiteKey = "6LfIn-klAAAAAK3dN7M9nmMrOJDQGXjGdSxd1aRr";

export default function App() {
    // load google recaptcha3 script
    useEffect(() => {
        // Start monitoring client, to determine if a robot.
        ReactRecaptchaV3.init(reCaptchaSiteKey).then(
            (status) => {
                // console.log(`G-ReCaptcha V3 Status: ${status}`);
            }
        );

        // Google analytics consent.
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
          handleAcceptCookie();
        }
    }, []);

    const handleAcceptCookie = () => {
        // Init Google analytics tracking with tracking code.
        // https://analytics.google.com/
        GoogleAnalytics.initialize('G-F54KPVE3BM');
        GoogleAnalytics.send({ hitType: "pageview", page: window.location.pathname, title: "Home" });
    };

    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    };

    return (
        <Router>
            <div id="App">
                { /* Example Persistent Component i.e. It displays for every page. */ }
                <Navbar />
                { /* Rendered Pages: Modify this file to add pages/routes/views. */}
                <ScrollToTop/>
                <RouterView />
                <Footer />
                <CookieConsent 
                    enableDeclineButton 
                    onAccept={handleAcceptCookie}
                    onDecline={handleDeclineCookie}
                    style={{background: "#1C2E3C", display: "flex", alignItems: "center"}}
                    buttonStyle={{background: "rgb(27, 117, 187)", color: "white"}}
                    buttonText="Accept Cookies"
                    declineButtonText="Decline Cookies"
                >
                    This website uses cookies to enhance the user experience.
                    <br/>
                    <a target="_blank" rel="noreferrer" href={privacyPolicyPDF} className='info-link'>Privacy Policy</a>
                </CookieConsent>
            </div>
        </Router>

    );
}
