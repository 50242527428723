/**
 * A component that represents a client / associate of DEV4 online.
 * @param {any} logo An imported image of the companies logo. 
 * @param {String} companyName
 * @param {String} website The web address of the companies website. 
 */
export default function Client({ logo, companyName, website }) {
    return (
        <a className="client-item" target="_blank" href={website} alt={`${companyName} website link`} >
            <img src={logo} alt={companyName} />
        </a>
    )
}