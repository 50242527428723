import "./footer.css";
import legalPDF from '../../../assets/documents/DEV4 Website T&C 18 July 2023.pdf';
import privacyPolicyPDF from '../../../assets/documents/DEV4 Privacy Policy 18 July 2023.pdf';

export default function Footer() {
    return (
        <footer>
            <div className="footer-legal-container">
                <p className="fs-md mb-0"><span>© 2023. All rights reserved. Property of DEV4 Online LTD.</span></p>
                
                DEV4 Online LTD.<br/>
                <a target="_blank" rel="noreferrer" href={privacyPolicyPDF} className="info-link">Privacy Policy</a>
                <br/>
                <a target="_blank" rel="noreferrer" href={legalPDF} className="info-link">Legal</a>

                <br/><br/>
                This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" className="info-link">Privacy Policy</a> and <a href="https://policies.google.com/terms" className="info-link">Terms of Service</a> apply.
            </div>
            <aside>
                <a className="icon-circle-btn" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/dev4-online/">
                    <i className="ai-linkedin"></i>
                </a>

                <a className="icon-circle-btn" target="_blank" rel="noreferrer" href="https://www.facebook.com/dev4.online/">
                    <i className="ai-facebook"></i>
                </a>

                <a className="icon-circle-btn" target="_blank" rel="noreferrer" href="https://www.youtube.com/@dev4online971">
                    <i className="ai-youtube"></i>
                </a>
            </aside>
        </footer>   
    )
}