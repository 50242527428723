import TransparentButton from "../../../common/Buttons/TransparentButton";

export default function Service({
    icon, // SVG icon
    title,
    subtitle,
    description,
    hoverImage // Background image that shows on hover.
}) {
    return (
        <div data-aos="fade-up" className="service-item">
            <div className="service-icon">
                <img src={icon} alt={title + " icon"} />
                <h6 className="service-subtitle">{subtitle}</h6>
            </div>
            <h3>{title}</h3>
            <p>{description}</p>
            <div className="service-footer">
                <TransparentButton icon="ai-circle-arrow-right" />
            </div>

            { /* Animated background that shows on hover. */ }
            <div className="hover-bg" style={{ backgroundImage: "url('" + hoverImage + "')" }}></div>
        </div> 
    );
}