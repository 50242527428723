import { CaseStudy, CaseStudyTemplate, Company } from "../CaseStudy";
import technologies from "../technologies";

import namakaLogo from "../../../../assets/img/clients/namaka-cropped.webp";
import namsThumbnail from "../../../../assets/img/caseStudies/NAMS.webp";

export default function NamakaSubseaNAMS() {
    const caseStudy = new CaseStudyTemplate(
        "NAMAKA Subsea: NAMS",
        (
            <div>
                <p>
                    DEV4 continue to work closely with Namaka Subsea to design, build, deliver and support the NAMS cloud-based SAAS system to their clients for supporting Diving, ROV and Marine equipment across the world.  Some of the largest global subsea organisations subscribe to NAMS to ensure their equipment is compliant with industry regulations.
                </p>

                <p>
                    NAMS is designed to optimise User Experience and integrate with other systems with customised API's and QR Codes.
                </p>

                <p>
                    Some of the key features in NAMS are the dynamic menus and layouts creating a navigation tool which is specific to individual customers data.  Its capability to integrate with other systems has made it an attractive offering to established marine companies and industry bodies in the marine sector.
                </p>
                <p>
                    Intitial system build time in 2020 : 9 months | 5 subsequent phases | User base: Over 70 Companies in the marine industry, across all continents |
                </p>
            </div>
        ),
        // Project Showcase Thumbnail
        namsThumbnail,
        new Company(
            "Namaka Subsea",
            // Company Logo
            namakaLogo,
            {
                width: "22.5%"
            }
        ),
        "https://www.namakasubsea.com/products/nams",
        [
            technologies.aspNET,
            technologies.netCore,
            technologies.azure,
            technologies.sqlServer,
            technologies.blazor
        ]
    )

    return (
        <CaseStudy caseStudy={caseStudy} thumbSize="95%" bgColor="rgb(102, 136, 203)"></CaseStudy>
    )
}