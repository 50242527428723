import "./caseStudies.css"
import OutlineAnchor from "../../common/Anchors/OutlineAnchor";

export class CaseStudyTemplate {
    constructor(title, description, background, company, linksTo, technologies, external) {
        this.title = title;
        this.description = description;
        this.background = background;
        this.company = company;
        this.linksTo = linksTo;
        // List of <Technology>(s).
        this.technologies = technologies;
        this.external = external ?? true;
    }
}

// Case Study Company Template.
export class Company {
    constructor(name, logo, styleOverride) {
        this.name = name;
        this.logo = logo;
        this.styleOverride = styleOverride;
    }
}

// Case Study Tech Stack Item.
export class Technology {
    constructor(name, logo, link, styleOverride) {
        this.name = name;
        this.logo = logo;
        this.link = link;
        this.styleOverride = styleOverride;
    }
}

export function CaseStudy({ caseStudy, bgColor, thumbSize }) {
    // For unique key of technology stack iterations.
    let technologyIndex = caseStudy.technologies.length;

    if(thumbSize) {
        thumbSize = {backgroundSize: thumbSize};
    } else {
        thumbSize = {};
    }

    return (
        <div className="case-study-container">
            <a target="_blank" rel="noopener noreferrer" className="case-study-image-container" href={caseStudy.linksTo}>
                {/* Coloured Background */}
                <div
                    className="case-study-background"
                    data-aos="zoom-in" data-aos-duration="600" data-aos-offset="120"
                    style={{
                        backgroundColor: bgColor
                    }}>
                </div>

                {/* Product Image */}
                <div
                    className="case-study-thumbnail"
                    data-aos="fade-in" data-aos-duration="400" data-aos-offset="120"
                    style={{
                        backgroundImage: `url('${caseStudy.background}')`,
                        ...thumbSize
                    }}>
                </div>
            </a>


            <aside data-aos="fade-up" data-aos-duration="200" data-aos-offset="50">
                <img className="mb-2 mb-lg-3 large-screen-only case-study-company-logo" src={caseStudy.company.logo} alt={caseStudy.company.name} style={caseStudy.company.styleOverride} />
                <h2 className="h4" style={{textAlign: "left"}}>
                    {caseStudy.title}
                </h2>
                <div className="fs-sm pb-3" style={{textAlign: "left"}}>
                    { caseStudy.description }
                </div>
                <OutlineAnchor path={caseStudy.linksTo} title="Get the details" external={caseStudy.external} />
                <div className="case-study-tech-stack pt-2 pt-lg-3 mt-3">
                    <h6 className="text-body mb-0">Tech:</h6>
                    {
                        caseStudy.technologies.map(technology => (
                            <a target="_blank" rel="noopener noreferrer" href={technology.link} key={technologyIndex--}>
                                <img className="d-block d-dark-mode-none" width="90" src={technology.logo} alt={technology.title} style={technology.styleOverride} />
                            </a>
                        ))
                    }
                </div>
            </aside>
        </div>
    )
}