import { CaseStudy, CaseStudyTemplate, Company } from "../CaseStudy";
import technologies from "../technologies";

import RISThumbnail from "../../../../assets/img/caseStudies/RIS.webp";
import RISLogo from "../../../../assets/img/clients/RISLOGO_INVERT.webp";

export default function Condi() {
    const caseStudy = new CaseStudyTemplate(
        "RIS Inspect",
        (
            <div>
                <p>
                    RIS engaged DEV4 in 2022 to develop a bespoke engineering and management system as part of a suite of products they are offering as SAAS.   
                </p>

                <p>
                    Within 3 months we designed, built and delivered an impressive working prototype, followed by a continuous partnership with RIS to deliver a roadmap of cutting-edge innovation.
                </p>

                <p>
                    The initial release was a <a href="https://developer.chrome.com/blog/getting-started-pwa/#what-is-a-progressive-web-app">Progressive Web Application</a>, focusing on advanced <a href="https://en.wikipedia.org/wiki/User_experience">UX</a>, including features such as: 

                    <ul>
                        <li>An app for mobile, tablet and desktop devices.</li> 
                        <li>Fast and intuitive input screens for quick data and photographic capture to be used for engineers whilst on a job.</li>
                        <li>Integration with procedural documentation and industry standards.</li>
                        <li>Interactive dashboards for management reporting and system navigation.</li>
                    </ul>

                </p>

                <p>
                    The success of this project has been down to good communication, getting to understand RIS as a business and designing a system that is leagues ahead of the competition. 
                </p>
            </div>
        ),
        // Project Showcase Thumbnail
        RISThumbnail,
        new Company(
            "RIS RIG Inspection Services",
            // Company Logo
            RISLogo,
            {
                width: "12.5%"
            }
        ),
        "https://riginspectionservices.com/",
        [
            technologies.PWA,
            technologies.aspNET,
            technologies.vueJS,
            technologies.telerik,
            technologies.azure,
            technologies.sqlServer,
        ]
    )

    return (
        <CaseStudy caseStudy={caseStudy} bgColor="rgb(252, 201, 162)" thumbSize="80%"></CaseStudy>
    )
}