import { CaseStudy, CaseStudyTemplate, Company } from "../CaseStudy";
import technologies from "../technologies";
import equitrackThumbnail from "../../../../assets/img/caseStudies/EQMob2.webp";
import equitrackLogo from "../../../../assets/img/clients/equ-logoAlt.webp";

export default function Equitrack() {
    const caseStudy = new CaseStudyTemplate(
        "Equi-Track V2",
        (
            <div>
                <p>
                    In 2019 DEV4 started the journey of building and advanced version of Equi-track with capabilites to track equipment with different IoT devices and QR Coding. This allows tracking of equipment in multiple ways to offer a rich set of data on asset location and condition, Equi-track V2 interfaces with:
                    <li>Non-powered GPS tracking devices with extended battery life, such as <a href="https://teltonika-gps.com/products/trackers/assets-workforce/tat100">Teltonka TAT100</a>.</li>
                    <li>Sensors for Temperature, Humidity, Altitude, Movement e.t.c. such as <a href="https://teltonika-gps.com/products/accessories/sensors-beacons/eye">Eye Beacons</a>.</li>
                    <li>QR Coding for fast identification of Assets and location from mobile phones.</li>               
                </p>
                <p>
                    Equi-track V2 is desined to integrate with any other system so companies can choose to use our own Equi-track cloud-based app or utilise the tracking data received with their own existing systems.  
                 </p>
            </div>
        ),
        // Project Showcase Thumbnail
       
        equitrackThumbnail,
        new Company(
            "Equitrack",
            // Company Logo
            equitrackLogo,
            {
                width: "22%",
            }
        ),
        "https://www.youtube.com/watch?v=nCrQhHcSwjc",
       
        [
            technologies.aspNET,
            technologies.telerik,
            technologies.azure,
            technologies.sqlServer
        ],
        
    )

    return (
        <CaseStudy caseStudy={caseStudy} bgColor="#0C2465" thumbSize="97.5%"></CaseStudy>
    )
}