import { CaseStudy, CaseStudyTemplate, Company } from "../CaseStudy";
import technologies from "../technologies";

import athenaThumbnail from "../../../../assets/img/caseStudies/Athena.webp";
import namakaLogo from "../../../../assets/img/clients/namaka-compliance-crop.webp";

export default function NamakaComplianceAthena() {
    const caseStudy = new CaseStudyTemplate(
        "Namaka Compliance: Athena",
        (
            <div>
                <p> Athena: an advanced online competence management tool, which allows organisations to demonstrate the competence of their personnel.</p>
                <p>
                    Namaka Compliance Ltd are focused on innovation, and engaged DEV4 to support and enhance their existing SAAS Competence Management System (CMS) in 2023.  The development roadmap for Athena future-proofs the system and adds value to its offerings, ensuring it keeps its competitive advantage.
                </p>
            </div>
        ),
        // Project Showcase Thumbnail
        athenaThumbnail,
        new Company(
            "Namaka Compliance",
            // Company Logo
            namakaLogo,
            {
                width: "18%",
            }
        ),
        "https://www.namakacompliance.com/products/athena",
        [
            technologies.netCore,
            technologies.vueJS,
            technologies.sqlServer,
            technologies.azure
        ]
    )

    return (
        <CaseStudy caseStudy={caseStudy} thumbSize="75%" bgColor="rgb(245,245,245)"></CaseStudy>
    )
}