import { useEffect } from "react";
import GoogleAnalytics from "react-ga4";

// Case Studies
import Equitrack from "../components/pages/caseStudies/Items/Equitrack";
import EquitrackV1 from "../components/pages/caseStudies/Items/EquitrackV1";
import NamakaComplianceAthena from "../components/pages/caseStudies/Items/NamakaComplianceAthena";
import NamakaSubseaNAMS from "../components/pages/caseStudies/Items/NamakaSubseaNAMS";
import StockTrack from "../components/pages/caseStudies/Items/StockTrack";
import TemsEagle from "../components/pages/caseStudies/Items/TemsEagle";
import TemsTiger from "../components/pages/caseStudies/Items/TemsTiger";
import Timeherent from "../components/pages/caseStudies/Items/Timeherent";
import Condi from "../components/pages/caseStudies/Items/Condi";
import RISInspect from "../components/pages/caseStudies/Items/RISInspect";

export default function CaseStudies() {
    useEffect(() => {
        GoogleAnalytics.send({ hitType: "pageview", page: window.location.pathname, title: "Case Studies" });
    }, []);

    return (
        <main className="below-navbar d4-container case-studies-section" style={{ marginLeft: "auto", marginRight: "auto" }}>
            <h1 className="pb-3">Just a few of our solutions over the last 15 years</h1>

            <div className="case-studies-container">
                <TemsEagle />
                <TemsTiger />
                <RISInspect />
                <NamakaSubseaNAMS />
                <NamakaComplianceAthena />
                <Condi />
                <EquitrackV1 />
                <Equitrack />
                <StockTrack />
                <Timeherent />
            </div>
        </main>
    )
}