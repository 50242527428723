import { CaseStudy, CaseStudyTemplate, Company } from "../CaseStudy";
import technologies from "../technologies";

import TigerThumbnail from "../../../../assets/img/caseStudies/TigerWeb.webp";
import temsLogo from "../../../../assets/img/clients/tems-cropped.webp";

export default function TemsTiger() {
    const caseStudy = new CaseStudyTemplate(
        "TEMS Tiger",
        (
            <div>
                <p>
                    In 2019 DEV4 worked with TEMS International to build a new module to enhance their suite of cloud-based solutions.  TEMS International identified a requirement and a gap in the market for this additional cloud-based service to reduce the environmental impact of waste management during the offshore drilling process.  The TIGER reporting system automates complex calculations and delivers instant real-time reports.
                </p>
                <p>
                    The TIGER application has been built using Progressive Web Application (PWA) Technologies to ensure optimisation on mobile devices and potential for offline working in restricted WiFi areas.
                </p>
                <p>
                    Intitial system build: 5 months | User base: Europe, Asia, the Americas and Australia |
                </p>
            </div>
        ),
        // Project Showcase Thumbnail
        TigerThumbnail,
        new Company(
            "TEMS",
            // Company Logo
            temsLogo
        ),
        "https://tems-international.com/tems-international-services/tems-international-eagle-reports",
        [
            technologies.PWA,
            technologies.netCore,
            technologies.blazor,
            technologies.sqlServer,
            technologies.azure
        ]
    )

    return (
        <CaseStudy caseStudy={caseStudy} bgColor="rgba(206, 209, 233, 1)" thumbSize="90%"></CaseStudy>
    )
}