// External Libraries
import { useState, useRef } from "react";
import ReactRecaptchaV2 from "react-google-recaptcha";
import Joi from "joi";

// Custom Components
import InputBox from "../../../common/Forms/InputBox";
import FormGroup from "../../../common/Forms/FormGroup";
import PrimaryButton from "../../../common/Buttons/PrimaryButton";
import { Alert, AlertModel } from "../../../common/Alert/Alert";
import ErrorLabel from "../../../common/Forms/ErrorLabel";

// Stylesheets
import "./contact.css";

// Custom Functions
import handleSubmit from "./handleSubmit";
import { clone } from "../../../../services/utils";


export default function Contact() {
    // Contact Form Data.
    const [contactInfo, setContactInfo] = useState(new ContactInfo());

    // API
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(AlertModel.initialiseAlert());
    const [errors, setErrors] = useState([]);

    // Recaptcha V2... Additional verification if V3 fails.
    const [recaptchaRequired, requireCaptcha] = useState(false);
    const recaptchaRef = useRef(null);

    const handleFormSubmit = async (e) => {
        // Prevent page from refreshing / navigating to another route.
        e.preventDefault();
        setErrors([]);

        const submission = {
            name: contactInfo.name,
            company: contactInfo.company,
            email: contactInfo.email,
            phone: contactInfo.phone,
            message: contactInfo.message
        };

        const customErrorMessages = {
            'string.base': 'The field {#label} must be a string.',
            'string.empty': 'The field {#label} cannot be empty.',
            'string.email': 'Please enter a valid email address for {#label}.',
            'any.required': 'The field {#label} is required.',
        };

        // Front-end validation.
        const validationSchema = Joi.object({
            name: Joi.string().required().messages(customErrorMessages),
            company: Joi.string().allow('').optional().messages(customErrorMessages),
            email: Joi.string().email({tlds: false}).required().messages(customErrorMessages),
            phone: Joi.string().allow('').optional().messages(customErrorMessages),
            message: Joi.string().required().messages(customErrorMessages),
          });

        const validation = validationSchema.validate(submission, { abortEarly: false });

        if(validation.error) {
            let localErrors = {};

            const errorArray = validation.error.details.map(error => ({
                path: error.path[0],
                message: error.message
            }));

            errorArray.forEach(error => {
                localErrors[error.path] = error.message;
            })

            setErrors(localErrors);
            return;
        }

        // If ReCaptcha V3 failed, append ReCaptcha V2 token.
        if (recaptchaRequired) {
            submission.reCaptchaV2Token = recaptchaRef.current.getValue();
            // Hide ReCaptcha component just in-case it needs to reshow.
            requireCaptcha(false);
        }

        // Send the POST request and update related state.
        handleSubmit(submission, setLoading, setAlert, requireCaptcha);
    }

    return (
        <section className="contact-container">
            <div className="contact-card d4-container" id="contact">
                <h1 className="display-6">Get a free design session</h1>
                <form onSubmit={handleFormSubmit}>
                    <FormGroup columns="2" colsGap="2.5%" marginTop="2%">
                        <InputBox label="Name" value={contactInfo.name} error={errors?.name} onChange={(e) => setContactInfo(contactInfo.updateProperty('name', e.target.value))} placeholder="Your name" type="text" labelStyle={{ color: "white" }} />
                        <InputBox label="Company" value={contactInfo.company} error={errors?.company} onChange={(e) => setContactInfo(contactInfo.updateProperty('company', e.target.value))} placeholder="Your company name" type="text" labelStyle={{ color: "white" }} />
                    </FormGroup>
                    <FormGroup columns="2" colsGap="2.5%" marginTop="3%">
                        <InputBox label="Email" value={contactInfo.email} error={errors?.email} onChange={(e) => setContactInfo(contactInfo.updateProperty('email', e.target.value))} placeholder="Email address" type="email" labelStyle={{ color: "white" }} />
                        <InputBox label="Phone" value={contactInfo.phone} error={errors?.phone} onChange={(e) => setContactInfo(contactInfo.updateProperty('phone', e.target.value))} placeholder="Phone number" type="text" labelStyle={{ color: "white" }} />
                    </FormGroup>

                    <InputBox label="How can we help?" value={contactInfo.message} error={errors?.message} onChange={(e) => setContactInfo(contactInfo.updateProperty('message', e.target.value))} placeholder="Enter your message here..." type="text" multiline="6" labelStyle={{ color: "white", marginTop: "3%" }} />
                    {recaptchaRequired && (
                        <div className="d-flex flex-column align-items-center mt-5">
                            <ReactRecaptchaV2
                                sitekey="6Ld39-klAAAAAGVXckCjcsry-cRleVom-qnhoJtJ"
                                ref={recaptchaRef}
                            />
                            <ErrorLabel message="Please verify that you're a human." />                    
                        </div>
                    )}

                    <div className="d-flex justify-content-center mt-5">
                        <PrimaryButton title="Send a request" loading={loading} />
                    </div>
                </form>
            </div>

            <Alert title={alert.title} message={alert.message} visible={alert.visible} onClose={() => setAlert(AlertModel.initialiseAlert())} />
        </section>
    )
}

// Layer of abstraction rather than many setState methods.
class ContactInfo {
    constructor() {
        this.name = '';
        this.company = '';
        this.email = '';
        this.phone = '';
        this.message = '';
    }

    // Clone a new object and update provided property.
    updateProperty(property, value) {
        let updatedContact = clone(this);
        updatedContact[property] = value;
        return updatedContact;
    }
}