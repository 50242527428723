import NavbarLink from "./NavbarLink";

// React List Key.
let linkIndex = 0;

/**
 * Create a list of navigation links using an Array.
 * @param {Array} NAVIGATION_LINKS An array of objects with each object containing a title and a path. 
 * @returns 
 */
export default function NavigationLinkContainer({ NAVIGATION_LINKS, mobileNavbar, closeNavbar }) { 
    if (!mobileNavbar) {
        // Desktop

        return (
            <div className="nav-container">
                {
                    NAVIGATION_LINKS.map((link) => (
                        <NavbarLink title={link.title} path={link.path} external={link.external} key={linkIndex++}></NavbarLink>
                    ))
                }
            </div>
        )
    } else {
        // Mobile
        return NAVIGATION_LINKS.map((link) => (
            <NavbarLink title={link.title} path={link.path} key={linkIndex++} closeNavbar={closeNavbar} external={link.external}></NavbarLink>
        ));
    }
}