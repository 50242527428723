import "./hero.css";
import heroSmall from "../../../../assets/img/banners/hero_small.webp";
import heroMedium from "../../../../assets/img/banners/hero_medium.webp";
import heroLarge from "../../../../assets/img/banners/hero_large.webp";
import { HashLink } from 'react-router-hash-link';
import { Parallax } from 'react-parallax';

export default function Hero() {
    return (
        <Parallax
            bgImage={heroLarge}
            bgImageAlt="Hero Image: Tailored Suit"
            strength={300}
            bgImageSrcSet={`${heroSmall} 500w, ${heroMedium} 1000w, ${heroLarge} 2560w`}
        >
          {/* ^ Smaller resolutions: https://stackoverflow.com/questions/28650327/responsive-images-srcset-not-working seems to be fixed. */}
          <div id="hero">
              <div className="card-holder">
                  <div className="card">
                      <div className="card-body text-white">
                          { /* Hero Title & Introduction */}
                          <h1 className="card-title text-white font-weight-bold display-6 mb-4">Tailored software to <br className="d-none d-sm-block" /> increase the value of your <br className="d-none d-sm-block" /> business</h1>
                          <p className="fs-5 desktop-only fw-light pb-4 mb-lg-3">Significantly increase your edge and value by incorporating your own software into your service delivery.</p>

                          { /* Contact Button & Number */ }
                          <div className="d-flex align-items-center contact-container">
                            <HashLink className="btn btn-lg btn-light" to="/#contact">Book a free consultation</HashLink>
                              {/* <a className="ms-3 desktop-only d-flex align-items-center fw-light text-white text-decoration-none px-3 py-2" href="tel:+15262200459">
                                  <i className="ai-phone fs-xl me-2"></i>
                                  +XX XXX XXX XXXX
                              </a> */}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </Parallax>
  );
}
