import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import "./anchor.css";

export default function OutlineAnchor({title, path, external}) {
    // If link is using a HashLink rather than a normal link.
    const LinkComponent = path.includes("#") ? HashLink : Link;

    // If linking outside of the site.
    if (external) {
        return (<a target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark rounded-pill" href={path}>{title}</a>)
    }

    return(
        // {/* You can not use standard anchor tags as that will cause the app to request the page from the server rather than React. */}
        <LinkComponent to={path} className="btn btn-sm btn-outline-dark rounded-pill">
            {title}
        </LinkComponent>
    )
}