import AboutItem from "../AboutItem";
import laptopOverShotImage from "../../../../../assets/img/other/LaptopOverShot.jpg";

export default function DeliverOnTime() {
    return (
        <AboutItem
            heading="Deliver it on Time and Within Budget"
            thumbnail={laptopOverShotImage}
            floatingIcon={
                <div className="zindex-1 d-none d-lg-block position-absolute top-0 start-0 translate-middle-x w-auto pt-3 mt-5">
                    <div data-aos="fade-up" data-aos-duration="950" data-aos-offset="280" className="aos-init">
                        <svg className="text-primary" width="160" height="160" viewBox="0 0 160 160" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="80" cy="80" r="80"></circle>
                        </svg>
                    </div>
                </div>
            }
        >
            {/* 
        
                <div id="about-side-by-side">
                    <div style={{ width: "25ch" }}>
                        <div className="text-muted mb-2">01</div>
                        <h3 className="h5">Individual approach</h3>
                        <p className="mb-0">Hac erat leo proin odio est sed sit in felis facilisi integer sed congue neque turpis dictumst sitsed volutpat aliquet tortor.</p>
                    </div>
                    <div style={{ width: "25ch", marginLeft: "7.5%" }}>
                        <div className="text-muted mb-2">02</div>
                        <h3 className="h5">Integrated analytics</h3>
                        <p className="mb-0">Find aute irure dolor in enderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur neque congue neque turpis.</p>
                    </div>
                </div>
        
            */}
            <div>
                <p className="fs-xl pb-3 mb-1 mb-sm-2 mb-md-3 mb-lg-4" style={{ width: '50ch' }}>
                    We will deliver your system in useable chunks.  Our phased approach to design and development means we can deliver systems quickly to match your requirements exactly.  
                </p>
                <p className="fs-xl pb-3 mb-1 mb-sm-2 mb-md-3 mb-lg-4" style={{ width: '50ch' }}>
                    Our confidence in our design process allows us to provide accurate fixed pricing.
                </p>

            </div>
        </AboutItem>
    )
}