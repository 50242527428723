import azureLogo from "../../../assets/img/tools/AzureLogo.webp";
import aspNetLogo from "../../../assets/img/tools/MicrosoftLogo.webp";
import blazorLogo from "../../../assets/img/tools/BlazorLogo.webp";
import xamarinLogo from "../../../assets/img/tools/XamarinLogo.webp";
import telerikLogo from "../../../assets/img/tools/TelerikLogo.webp";
import sqlServerLogo from "../../../assets/img/tools/SQLServerLogo.webp";
import pwaLogo from "../../../assets/img/tools/PWA_Logo.webp";
import netCoreLogo from "../../../assets/img/tools/NETCore.webp";
import vueLogo from "../../../assets/img/tools/VueLogo.webp";
import { Technology } from "./CaseStudy";

export default {
    azure: new Technology("Azure", azureLogo, "https://azure.microsoft.com/en-gb"),
    blazor: new Technology("Blazor", blazorLogo, "https://dotnet.microsoft.com/en-us/apps/aspnet/web-apps/blazor"),
    aspNET: new Technology("ASP.NET", aspNetLogo, "https://dotnet.microsoft.com/en-us/apps/aspnet"),
    xamarin: new Technology("Xamarin", xamarinLogo, "https://dotnet.microsoft.com/en-us/apps/xamarin"),
    telerik: new Technology("Telerik", telerikLogo, "https://www.telerik.com/"),
    sqlServer: new Technology("SQL Server", sqlServerLogo, "https://www.microsoft.com/en-gb/sql-server/"),
    PWA: new Technology("Progressive Web App", pwaLogo, "https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps", {
        width: "60px"
    }),
    netCore: new Technology(".Net Core", netCoreLogo, "https://dotnet.microsoft.com/en-us/download", {
        width: "50px"
    }),
    vueJS: new Technology("Vue.js", vueLogo, "https://vuejs.org/")
}